import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const UserTestimonial = ({ heading, quote, author }) => {
  const { i18n } = useTranslation();
  const [videoSrc, setVideoSrc] = useState('');

  useEffect(() => {
    setVideoSrc(`${process.env.PUBLIC_URL}/${i18n.language === 'de' ? 'Maggie.webm' : 'maggie_english.webm'}`);
  }, [i18n.language]);

  return (
    <div className="max-w-4xl mx-auto py-12">
      <h2 className="text-3xl font-bold text-center mb-8">{heading}</h2>
      <div className="lg:flex lg:items-center lg:space-x-8">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <video key={videoSrc} controls className="w-full rounded-lg shadow-lg">
            <source src={videoSrc} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="lg:w-1/2">
          <blockquote className="text-xl italic text-center font-semibold text-gray-900">
            <p className="mb-4">"{quote}"</p>
            <footer className="text-sm text-gray-600 text-right">- {author}</footer>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default UserTestimonial;
