import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Mail } from 'lucide-react';

const CheckEmailToAccessOrderInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <Mail className="text-purple-600 w-16 h-16 mb-4" />
      <h1 className="text-2xl font-bold text-center mb-4">
        {t('checkEmail.header')}
      </h1>
      <div className="flex space-x-4">
        <Link
          to="https://kita.kids"
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
        >
          {t('checkEmail.home')}
        </Link>
        <Link
          to="/form"
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
        >
          {t('checkEmail.placeNewOrder')}
        </Link>
      </div>
    </div>
  );
};

export default CheckEmailToAccessOrderInfo;
