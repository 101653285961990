import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';

const PreviewCards = ({ previewCards }) => {
  const [enlargedImage, setEnlargedImage] = useState(null);
  const { t } = useTranslation();

  const handleImageClick = (card) => {
    setEnlargedImage(card);
  };

  const handleCloseEnlarged = () => {
    setEnlargedImage(null);
  };

  return (
    <div className="space-y-4">
      <p className="mb-8 text-gray-700 text-lg leading-relaxed max-w-2xl mx-auto">
        {t('previewCards.description')}
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
        {previewCards.map((card, index) => (
          <div key={index} className="relative overflow-hidden cursor-pointer rounded-lg" onClick={() => handleImageClick(card)}>
            <img 
              src={card.thumbnails.card_cover.url} 
              alt={`Preview Card ${index + 1}`} 
              className="w-full h-auto object-cover rounded-lg" 
            />
          </div>
        ))}
      </div>
      {enlargedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={handleCloseEnlarged}>
          <div className="relative max-w-3xl max-h-full">
            <img 
              src={enlargedImage.url} 
              alt="Enlarged Preview Card" 
              className="max-w-full max-h-[90vh] object-contain"
            />
            <button 
              className="absolute top-4 right-4 text-white hover:text-gray-300"
              onClick={handleCloseEnlarged}
            >
              <X size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewCards;
